<template>
  <div>
    <h3>Корзина</h3>

    <div class="mini_cart_table">
      <div class="cart_total">
        <span>Итого:</span>
        <span class="price"><span class="ms2_total_cost">{{ total }}</span> Руб.</span>
      </div>
    </div>

    <template v-if="total > 0">
      <div
          v-for="item in cart"
          :key="item.key"
          class="cart_item">

        <div class="cart_img" style="width: 12%;">
          <a :href="item.properties.link">
            <img
                :src="item.properties.image"
                :alt="item.properties.name"
                :title="item.properties.name"
            >
          </a>
        </div>
        <div class="cart_info" style="width: calc(88% - 10px); ">
          <a :href="item.properties.link">
            {{ item.properties.name }}
          </a>

          <div class="d-flex">
            <span class="quantity">{{ item.count }} шт.</span>
            <span class="price_cart mx-3">{{ item.price }} Руб.</span>
          </div>

        </div>
      </div>
    </template>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "MiniCart",
  props: ['user_id'],
  created() {
    this.getCart()
  },
  data() {
    return {
      total: 0,
      cart: {}
    }
  },
  methods: {
    async getCart() {
      const data = [];
      data.push({name: 'action', value: 'get_cart'});
      if (this.user_id !== undefined) {
        data.push({name: 'user_id', value: this.user_id});
      }
      const response = await utils.send(data)
      this.total = response.status.total_cost
      this.cart = response.cart
    },
  }
}
</script>

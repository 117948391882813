<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal-body">
        <div class="form-group">
          <label>По номеру телефона <span class="required">*</span></label>
          <vue-tel-input
              :defaultCountry="'ru'"
              :mode="'international'"
              :validCharactersOnly="true"
              v-model="telInputValue"
              :dropdownOptions="telDropdownOptions"
              :inputOptions="telInputOptions"
              :styleClasses="telInputStyleClasses"
              @validate="telInputValidate"
              @input="telInputMask"
          ></vue-tel-input>
          <div
              v-if="phoneValidationFeedBack !== null"
              class="invalid-feedback">
            {{ phoneValidationFeedBack }}
          </div>


          <div class="alert alert-warning my-4" role="alert">
            Вам поступит проверочный звонок. <br>Введите 4 последние цифры входящего номера.
          </div>
        </div>

        <a href="#" class="btn btn-link"
           @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
        >Войти с помощью пароля</a>

      </div>

      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Просто закрыть</button>
        <button
            class="btn btn-success d-flex align-items-center"
            :disabled="disabled"
            @click="send"
        >
        <span v-if="loading" class="spinner-border spinner-border-sm spinner-btn mr-2" role="status"
              aria-hidden="true"></span>
          Получить код
        </button>
      </div>
    </div>

    <div v-if="view === 'page'">
      <div class="form-group">
        <label>По номеру телефона <span class="required">*</span></label>
        <vue-tel-input
            :defaultCountry="'ru'"
            :mode="'international'"
            :validCharactersOnly="true"
            v-model="telInputValue"
            :dropdownOptions="telDropdownOptions"
            :inputOptions="telInputOptions"
            :styleClasses="telInputStyleClasses"
            @validate="telInputValidate"
            @input="telInputMask"
        ></vue-tel-input>
        <div
            v-if="phoneValidationFeedBack !== null"
            class="invalid-feedback">
          {{ phoneValidationFeedBack }}
        </div>

        <div class="alert alert-warning my-4" role="alert">
          Вам поступит проверочный звонок. <br>Введите 4 последние цифры входящего номера.
        </div>
      </div>

      <div class="d-flex justify-align-center justify-content-between">
        <a href="#" class="btn btn-link"
           @click.prevent="$emit('changeInput', {input:'PasswordInput'})"
        >Войти с помощью пароля</a>

        <button
            class="btn btn-success d-flex align-items-center"
            :disabled="disabled"
            @click="send"
        >
        <span v-if="loading" class="spinner-border spinner-border-sm spinner-btn mr-2" role="status"
              aria-hidden="true"></span>
          Получить код
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import request from "@/request";

export default {
  name: "PhoneInput",
  components: {
    VueTelInput,
  },
  props: ['clientPhone', 'view'],
  created() {
    this.telInputValue = this.clientPhone
  },
  data() {
    return {
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form-control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled: true
      },
      telInputOptions: {
        placeholder: 'Введите номер телефона',
        required: true,
        name: 'phone'
      },
      phoneValidationFeedBack: null,
      disabled: true,
      loading: false,
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form-control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
        this.getCodeDisabled = false
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
      if (value === '77') {
        this.telInputValue = '+77'
      }
      if (value === '79') {
        this.telInputValue = '+79'
      }
    },
    async send() {
      this.loading = true
      this.disabled = true
      this.phoneValidationFeedBack = null
      this.telInputStyleClasses = ['form-control']

      const formdata = new FormData()
      formdata.append('phone', this.phone)
      const response = await request.post('/api/v1/auth/flashcall', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.disabled = false

        if (response.data.message !== '') {
          this.telInputStyleClasses.push('is-invalid')
          this.phoneValidationFeedBack = response.data.message
        }
      }

      if (response.data.success === true) {
        this.$emit('changeInput', {
          phone: this.phone,
          input: 'FlashCallInput',
          user_exists: response.data.user_exists,
        })
      }
    },
  },
}
</script>

<style scoped>
.auth__text {
  font-weight: 700;
  margin-top: 20px;
  font-size: 1.3em;
  text-align: center;
}

.spinner-btn {
  position: static;
}
</style>
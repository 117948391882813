<template>
    <div class="mt-3">
        <p v-if="currentTime >0">Отправить код ещё раз через {{ currentTime }} сек.</p>
        <a v-else href="#" @click="sendAgian">Отправить еще раз</a>
    </div>
</template>

<script>
    export default {
        name: "Timer",
        data() {
            return {
                currentTime: 59,
                timer: null,
            }
        },
        mounted() {
            this.startTimer()
        },
        destroyed() {
            this.stopTimer()
        },
        methods: {
            startTimer() {
                this.timer = setInterval(() => {
                    this.currentTime--
                }, 1000)
            },
            stopTimer() {
                clearTimeout(this.timer)
            },
            sendAgian() {
                this.$emit('sendAgain')
            },
        },
        watch: {
            currentTime(time) {
                if (time === 0) {
                    this.stopTimer()
                }
            }
        },
    }
</script>

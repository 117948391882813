<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-6 order-1 order-lg-0">
          <h3>Где вы хотите получить заказ?</h3>
          <template v-if="city === ''">
            <div class="alert-danger p-2">
              <p>
                Укажите ваш город
              </p>
            </div>
          </template>

          <template v-if="!emptyLocationData">
            <Location
                :city="city"
                :country="'ru'"
                @changeCity="changeCity"
            ></Location>
          </template>

          <template v-if="loading && deliveries.length === 0">
            <div class="alert-info p-2">
              <p>Мы рассчитываем лучшие способы доставки в ваш город</p>
            </div>
          </template>

          <template v-if="city !== ''">
            <h3 class="mt-4">Как вы хотите получить заказ?</h3>
            <div
                class="delivery__list mt-4"
            >

              <label
                  v-for="item in deliveries"
                  :key="item.id"
                  class="delivery__method"
                  :class="{'delivery__method--active': delivery === item.id}"
                  :for="'delivery_' + item.id"
              >
                <input
                    type="radio"
                    name="delivery"
                    :id="'delivery_' + item.id"
                    :value="item.id"
                    @change="deliverySelect"
                >
                <span class="delivery__method-icon">
                  <i :class="item.icon"></i>
                </span>

                <span class="delivery__method-info">
                  <span class="delivery__method-name d-block">{{ item.name }}</span>
                  <span class="delivery__method-details d-block">{{ item.days }} - {{ item.price }}</span>
                </span>
              </label>
            </div>

            <h3 class="mt-4">Как вы хотите оплатить заказ?</h3>

            <div
                class="payment__list"
            >
              <label
                  v-for="item in payments"
                  :key="item.id"
                  class="payment__method"
                  :class="{'payment__method--active': payment === item.id}"
                  :for="'payment_' + item.id"
              >
                <input
                    type="radio"
                    name="payment"
                    :id="'payment_' + item.id"
                    :value="item.id"
                    @change="paymentSelect">
                <span class="payment__method-icon">
                  <i :class="item.icon"></i>
                </span>
                <span class="payment__method-info">
                  <span class="payment__method-name">{{ item.name }}</span>
                </span>
              </label>
            </div>

            <template>
              <button v-if="disabledButton"
                      class="button mt-4 mb-4"
                      :disabled="disabledButton"
              >
                Выберите способы доставки и оплаты
              </button>

              <button v-else
                      class="button mt-4 mb-4"
                      @click="submit"
              >
                Продолжить
              </button>
            </template>

            <p>
              * Стоимость доставки указана в качестве справочной информации. Точную стоимость доставки вам сообщит
              менеджер при комплектации заказа
            </p>
          </template>

          <div
              v-if="loading"
              class="spinner-border text-danger login__form-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <MiniCart
              :user_id="user_id"
          />
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Location from "../components/Location"
import utils from "@/utils";
import MiniCart from "../components/MiniCart";

export default {
  name: "SposobDostavkiZakaza",
  components: {
    Location,
    MiniCart
  },
  created() {
    if (this.$parent.userId !== undefined) {
      this.user_id = this.$parent.userId
    }
    this.getUser()
  },
  computed: {
    disabledButton: function () {
      return !(this.payment > 0 && this.delivery > 0)
    }
  },
  data() {
    return {
      user_id: 0,
      emptyLocationData: true,
      city: '',
      isSaintPetersburg: false,
      payment: 0,
      delivery: 0,
      payments: [],
      deliveries: [],

      cart_cost: 0,
      cart: [],

      paymentsData: [
        {id: 1, icon: 'fas fa-money-bill-alt', name: 'Наличными или картой - в шоу-руме'},
        {id: 3, icon: 'far fa-credit-card', name: 'Оплата картой онлайн'},
        {id: 4, icon: 'fas fa-money-bill-alt', name: 'Оплата курьеру наличными (по Санкт-Петербургу)'},
      ],
      deliveriesData: [
        {id: 1, icon: 'fas fa-user', name: 'Самовывоз из магазина', price: 'бесплатно', days: 'Сегодня'},
        {id: 3, icon: 'fas fa-truck', name: 'Доставка курьером до двери', price: 'от 999999 руб.', days: 'от 99 дней'},
        {
          id: 2,
          icon: 'fas fa-map-marker-alt',
          name: 'Доставка до пункта выдачи заказов',
          price: 'от 999999 руб.',
          days: 'от 99 дней'
        },
      ],
      loading: false,
    }
  },
  methods: {
    async getUser() {
      this.loading = true
      const formdata = new FormData()
      formdata.append('action', 'getUser')
      formdata.append('user_id', this.user_id)

      const response = await utils.request(formdata)
      if (response.success === true) {
        this.city = response.user.city
        await this.updateDeliveryData()
      }
      this.loading = false
      this.emptyLocationData = false

    },
    changeCity(value) {
      this.city = value
      this.payment = 0
      this.delivery = 0
      this.updateDeliveryData()
    },
    async updateDeliveryData() {
      this.isSaintPetersburg = false
      if (this.city === 'Санкт-Петербург') {
        this.isSaintPetersburg = true
      }

      this.deliveries = []
      this.payments = []
      this.loading = true
      await this.deliveryCalculate()
      this.loading = false
    },
    async deliveryCalculate() {
      const response = await utils.send([
        {name: 'action', value: 'delivery_calculate'},
        {name: 'city', value: this.city},
        {name: 'isSaintPetersburg', value: this.isSaintPetersburg},
      ])
      console.log(response)
      if (response.data.length > 0) {
        response.data.forEach(method => {
          const delivery = this.deliveriesData.find(item => item.id === method.delivery)
          delivery.price = method.price
          delivery.days = method.date
          this.deliveries.push(delivery)
        })
        console.log(this.isSaintPetersburg, this.city)
        if (this.isSaintPetersburg) {

          this.payments = this.paymentsData
        } else {
          this.payments = this.paymentsData.filter(item => item.id === 3)
        }

      }
    },
    deliverySelect(event) {
      this.delivery = parseInt(event.target.value)

      switch (this.delivery) {
        case 1:
          this.payments = this.paymentsData.filter(item => item.id !== 4)
          break;
        case 2:
          this.payments = this.paymentsData.filter(item => item.id === 3)
          break;
        case 3:
          if (this.isSaintPetersburg) {
            this.payments = this.paymentsData.filter(item => item.id !== 1)
          } else {
            this.payments = this.paymentsData.filter(item => item.id === 3)
          }
          break;
      }
    },
    paymentSelect(event) {
      this.payment = parseInt(event.target.value)
    },
    async submit() {
      const v = this;

      new Promise(resolve => {
        utils.send([
          {name: 'action', value: 'order_add'},
          {name: 'user_id', value: v.user_id},
          {name: 'city', value: v.city},
          {name: 'delivery', value: v.delivery},
          {name: 'payment', value: v.payment},
        ]);
        setTimeout(() => {
          resolve()
        }, 500)
      })
          .then(() => {
            return utils.send([
              {name: 'action', value: 'getStep2'},
              {name: 'user_id', value: v.user_id},
            ]);
          })
          .then((response) => {
            if (response.success === true && response.url !== undefined) {
              location.href = '/' + response.url
            }
          })
    },
  },
}
</script>
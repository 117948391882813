<template>
  <form class="user_city_form">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title" id="ModalLocationSelectTitle">Ваш город</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="cityTitle">Если мы неверно определили ваш город, можете указать верный вариант</div>

        <div class="form-group">
          <vue-dadata
              token="8cc6a602befe4bd84bfb867b4dd92a2f5bab8c7c"
              placeholder="Ваш город"
              :query="city"
              :classes="'form-group mt-4'"
              :fromBound="'city'"
              :toBound="'city'"
              :onChange="changeCity"
          />
        </div>

      </div>
      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-outline-danger" data-dismiss="modal">Просто закрыть</button>
        <button @click="save" class="btn btn-success">Сохранить изменения</button>
      </div>
    </div>
  </form>
</template>

<script>
import VueDadata from 'vue-dadata'
import request from "@/request";

export default {
  name: "ModalLocationSelect",
  components: {
    'vue-dadata': VueDadata
  },
  created() {
    if (this.$parent.defaultCity !== undefined) {
      this.city = this.$parent.defaultCity
    }
  },
  data() {
    return {
      city: '',
    }
  },
  methods: {
    changeCity(event) {
      // eslint-disable-next-line no-prototype-builtins
      if (event.hasOwnProperty('data')) {
        this.city = event.data.city
        //this.$emit('changeCity', event.data.city)
        return
      }
      // eslint-disable-next-line no-prototype-builtins
      if (event.target) {
        this.city = event.target.value
        //this.$emit('changeCity', event.target.value)
      }
    },
    async save(e) {
      e.preventDefault();

      const response = new Promise((resolve) => {
        const formdata = new FormData()
        formdata.append('action', 'changeUserData')
        formdata.append('city', this.city)
        request.post('/', formdata)
        resolve()
      })

      response.then(() => {
        window.geolocation.init()
        setTimeout(() => {
          location.reload()
        }, 500)
      })
    }
  },
}
</script>
<style lang="scss">
.vue-dadata {
  &__input {
    border-color: #495057;
    padding: 0 10px;

    &:focus {
      border-color: #495057;
      box-shadow: none;
    }
  }
}
</style>
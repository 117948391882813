<template>
  <div>
    <div v-if="view === 'modal'">
      <div class="modal-body">
        <div class="alert alert-primary" role="alert">
          На номер <strong>{{ clientPhone }}</strong> поступит звонок<br>
          Брать трубку не обязательно.<br>
          Введите 4 последние цифры звонящего номера
        </div>
        <div class="d-flex justify-content-center">
          <OtpInput
              ref="otpInput"
              input-classes="otp-input"
              separator="-"
              :num-inputs="4"
              :should-auto-focus="true"
              :is-input-num="true"
              @on-complete="handleOnComplete"
              name="otp"
          ></OtpInput>
        </div>
        <div class="alert alert-danger my-4" role="alert"  v-if="validationFeedBack !== null">
          <strong>{{ validationFeedBack }}</strong>
        </div>

        <div class="alert alert-primary my-4" role="alert"
             v-if="!showSms"
        >
          Не поступает звонок?<br> Запросить код в SMS можно будет через
          <timer @stopTimer="stopTimer"/> сек
        </div>

        <template v-if="userExists === true">
          <a href="#" class="btn btn-link">Войти с помощью пароля</a>
        </template>
        <template v-else>
          <div class="alert alert-warning my-4" role="alert">
            Пользователь с номером <strong>{{ clientPhone }}</strong> не найден. <br> После подтверждения номера телефона
            мы зарегистрируем для вас новый профиль.
          </div>
        </template>
      </div>

      <div class="modal-footer justify-content-between">
        <button type="button" class="btn btn-outline-danger"
                @click="$emit('changeInput', {input: 'PhoneInput'})"
        >Назад
        </button>
        <button
            v-if="showSms"
            class="btn btn-outline-primary"
            @click="$emit('changeInput', {input:'SmsInput'})"
        >
          Запросить вход по СМС
        </button>
      </div>
      <div
          v-if="loading"
          class="spinner-border text-danger login__form-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>

    <div v-if="view === 'page'">
      <div class="alert alert-primary" role="alert">
        На номер <strong>{{ clientPhone }}</strong> поступит звонок<br>
        Брать трубку не обязательно.<br>
        Введите 4 последние цифры звонящего номера
      </div>
      <div class="d-flex justify-content-center">
        <OtpInput
            ref="otpInput"
            input-classes="otp-input"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            :is-input-num="true"
            @on-complete="handleOnComplete"
            name="otp"
        ></OtpInput>
      </div>
      <div class="alert alert-danger my-4" role="alert"  v-if="validationFeedBack !== null">
        <strong>{{ validationFeedBack }}</strong>
      </div>

      <div class="alert alert-primary my-4" role="alert"
           v-if="!showSms"
      >
        Не поступает звонок?<br> Запросить код в SMS можно будет через
        <timer @stopTimer="stopTimer"/> сек
      </div>

      <template v-if="userExists === true">
        <a href="#" class="btn btn-link">Войти с помощью пароля</a>
      </template>
      <template v-else>
        <div class="alert alert-warning my-4" role="alert">
          Пользователь с номером <strong>{{ clientPhone }}</strong> не найден. <br> После подтверждения номера телефона
          мы зарегистрируем для вас новый профиль.
        </div>
      </template>

      <div class="justify-content-between">
        <button type="button" class="btn btn-outline-danger"
                @click="$emit('changeInput', {input: 'PhoneInput'})"
        >Назад
        </button>
        <button
            v-if="showSms"
            class="btn btn-outline-primary"
            @click="$emit('changeInput', {input:'SmsInput'})"
        >
          Запросить вход по СМС
        </button>
      </div>
      <div
          v-if="loading"
          class="spinner-border text-danger login__form-spinner" role="status">
        <span class="sr-only">Loading...</span>
      </div>

    </div>
  </div>
</template>

<script>
import OtpInput from "@bachdgvn/vue-otp-input";
import request from "@/request";
import Timer from "@/components/Widgets/Timer"

export default {
  name: "FlashCallInput",
  components: {
    OtpInput,
    Timer
  },
  props: ['userExists', 'clientPhone', 'view'],
  data() {
    return {
      loading: false,
      validationFeedBack: null,
      showSms: false
    }
  },
  methods: {
    async handleOnComplete(value) {
      this.loading = true
      this.validationFeedBack = null
      const formdata = new FormData()
      formdata.append('code', value)
      formdata.append('phone', this.clientPhone)
      const response = await request.post('/api/v1/auth/check-code', formdata);

      if (response.data.success === false) {
        this.loading = false
        this.validationFeedBack = response.data.message
      }

      if (response.data.success === true) {
        this.loading = false
        await this.auth()
      }
    },
    async auth() {
      const formdata = new FormData()
      formdata.append('phone', this.clientPhone)
      const response = await request.post('/api/v1/auth', formdata);
      if (response.data.success === true) {
        location.reload()
      }
      if (response.data.success === false && response.data.message) {
        this.validationFeedBack = response.data.message
      }
    },
    stopTimer() {
      this.showSms = true
    },
  },
}
</script>

<style lang="scss">
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  text-align: center;

  &.error {
    border: 1px solid red !important;
  }
}

.otp-input::-webkit-inner-spin-button,
.otp-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.auth__text {
  font-weight: 700;
  font-size: 1.3em;
  text-align: center;
}


</style>
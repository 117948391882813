<template>
<form class="form login__form">
  <template v-if="activeComponent === 'PhoneInput'">
    <PhoneInput
        @changeInput="changeInput"
        :clientPhone="phone"
        :view="'page'"
    ></PhoneInput>
  </template>

  <template v-if="activeComponent === 'FlashCallInput'">
    <FlashCallInput
        @changeInput="activeComponent = $event.input"
        :userExists="userExists"
        :clientPhone="phone"
        :view="'page'"
    ></FlashCallInput>
  </template>

  <template v-if="activeComponent === 'SmsInput'">
    <SmsInput
        @changeInput="activeComponent = $event.input"
        :userExists="userExists"
        :clientPhone="phone"
        :view="'page'"
    ></SmsInput>
  </template>

  <template v-if="activeComponent === 'PasswordInput'">
    <PasswordInput
        @changeInput="activeComponent = $event.input"
        :view="'page'"
    ></PasswordInput>
  </template>
</form>
</template>

<script>
import PhoneInput from '../ModalAuth/PhoneInput'
import FlashCallInput from '../ModalAuth/FlashCallInput'
import SmsInput from '../ModalAuth/SmsInput'
import PasswordInput from '../ModalAuth/PasswordInput'

export default {
  name: "loginForm",
  components: {
    PhoneInput,
    FlashCallInput,
    SmsInput,
    PasswordInput
  },
  data() {
    return {
      activeComponent: 'PhoneInput',
      phone: '',
      userExists: false
    }
  },
  methods: {
    changeInput(data) {
      this.activeComponent = data.input
      if (data.phone) {
        this.phone = data.phone
      }
      if (data.user_exists) {
        this.userExists = data.user_exists
      }

    }
  },
}
</script>
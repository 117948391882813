<template>
  <div class="form-group mt-4">
    <label>Город</label>
    <vue-dadata
        token="8cc6a602befe4bd84bfb867b4dd92a2f5bab8c7c"
        placeholder="Ваш город"
        :query="location_city"
        :classes="'form-group'"
        :fromBound="'city'"
        :toBound="'city'"
        :onChange="changeCity"
    >
    </vue-dadata>
  </div>
</template>

<script>
import VueDadata from 'vue-dadata'

export default {
  name: "Location",
  props:['city'],
  data() {
    return {
      location_city: this.city,
    }
  },
  methods: {
    changeCity(event) {
      // eslint-disable-next-line no-prototype-builtins
      if (event.hasOwnProperty('data')) {
        this.location_city = event.data.city
        this.$emit('changeCity', event.data.city)
        return
      }
      // eslint-disable-next-line no-prototype-builtins
      if (event.target) {
        this.location_city = event.target.value
        this.$emit('changeCity', event.target.value)
      }
    }
  },
  components: {
    'vue-dadata': VueDadata
  }
}
</script>

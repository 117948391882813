<template>
  <div class="modal-content">
    <div class="modal-header">
      <p class="modal-title" id="ModalAuthTitle">Вход или Регистрация</p>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <template v-if="activeComponent === 'PhoneInput'">
      <PhoneInput
          @changeInput="changeInput"
          :clientPhone="phone"
          :view="'modal'"
      ></PhoneInput>
    </template>

    <template v-if="activeComponent === 'FlashCallInput'">
      <FlashCallInput
          @changeInput="activeComponent = $event.input"
          :userExists="userExists"
          :clientPhone="phone"
          :view="'modal'"
      ></FlashCallInput>
    </template>

    <template v-if="activeComponent === 'SmsInput'">
      <SmsInput
          @changeInput="activeComponent = $event.input"
          :userExists="userExists"
          :clientPhone="phone"
          :view="'modal'"
      ></SmsInput>
    </template>

    <template v-if="activeComponent === 'PasswordInput'">
      <PasswordInput
          @changeInput="activeComponent = $event.input"
          :view="'modal'"
      ></PasswordInput>
    </template>
  </div>
</template>

<script>

import PhoneInput from './PhoneInput'
import FlashCallInput from './FlashCallInput'
import SmsInput from './SmsInput'
import PasswordInput from './PasswordInput'

export default {
  name: "ModalAuth",
  components: {
    PhoneInput,
    FlashCallInput,
    SmsInput,
    PasswordInput
  },
  data() {
    return {
      activeComponent: 'PhoneInput',
      phone: '',
      userExists: false
    }
  },
  methods: {
    changeInput(data) {
      this.activeComponent = data.input
      if (data.phone) {
        this.phone = data.phone
      }
      if (data.user_exists) {
        this.userExists = data.user_exists
      }

    }
  },
}
</script>
<template>
  <form class="form login__form" id="login-by-sms">
    <div class="form-group">
      <label>По номеру телефона <span class="required">*</span></label>
      <vue-tel-input
          :defaultCountry="'ru'"
          :mode="'international'"
          :validCharactersOnly="true"
          v-model="telInputValue"
          :dropdownOptions="telDropdownOptions"
          :inputOptions="telInputOptions"
          :styleClasses="telInputStyleClasses"
          :disabled="sendSms"
          @validate="telInputValidate"
          @input="telInputMask"
      ></vue-tel-input>

      <div
          v-if="phoneValidationFeedBack !== null"
          class="invalid-feedback">
        {{ phoneValidationFeedBack }}
      </div>
    </div>

    <div
        v-if="!sendSms"
        class="login_submit mt-4"
    >
      <button
          :disabled="disabled"
          @click.prevent="submit"
      >
        Получить код в SMS
      </button>
    </div>

    <div
        v-if="sendSms"
        class="form-group mt-4"
    >
      <label for="login-by-sms-code">Код из SMS <span class="required">*</span></label>

      <input
          type="text"
          class="form-control"
          id="login-by-sms-code"
          placeholder="****"
          name="code"
          @input="inputCode"
      >

      <div
          v-if="codeValidationFeedBack !== null"
          class="invalid-feedback">
        {{ codeValidationFeedBack }}
      </div>

      <a
          href="#"
          class="mt-3 d-block"
          @click="clear"
      >
        Изменить номер телефона
      </a>

      <timer
        @sendAgain="sendAgain()"
      />
    </div>

    <div
        v-if="loading"
        class="spinner-border text-danger login__form-spinner" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </form>
</template>

<script>
import request from "@/request";
import {VueTelInput} from 'vue-tel-input'
import Timer from "./Timer"

export default {
  data() {
    return {
      phone: '',
      loading: false,
      telInputValue: '',
      telInputStyleClasses: ['form-control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled:true
      },
      telInputOptions: {
        placeholder: 'Введите номер телефона',
        required: true,
        name: 'phone'
      },
      disabled: true,
      sendSms: false,
      codeValidationFeedBack: null,
      phoneValidationFeedBack: null,
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form-control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
    },
    async submit() {
      if (this.phone === '') {
        this.telInputStyleClasses.push('is-invalid')
      }
      this.loading = true
      const formdata = new FormData()
      formdata.append('action', 'send_sms')
      formdata.append('phone', this.phone)
      const response = await request.post('/', formdata)


      this.telInputStyleClasses = ['form-control']
      this.phoneValidationFeedBack = null
      if (response.success === true) {
        this.loading = false
        this.sendSms = true
      }

      if (response.success === false) {
        this.loading = false
        this.telInputStyleClasses.push('is-invalid')
        this.phoneValidationFeedBack = response.message
      }
    },
    clear() {
      this.sendSms = false
    },
    sendAgain() {
      this.sendSms = false
      this.submit()
    },
    async inputCode(event) {
      const value = event.target.value
      if (value.length === 4) {
        await this.checkCode(value, event.target)
      }
    },
    async checkCode(code, input) {
      this.loading = true
      input.classList.remove('is-invalid', 'is-valid')
      const formdata = new FormData()
      formdata.append('action', 'check_sms_code')
      formdata.append('phone', this.phone)
      formdata.append('code', code)
      const response = await request.post('/', formdata)

      this.loading = false
      input.value = ''
      if (response.success === true) {
        input.classList.add('is-valid')
        location.href = '/sposob-dostavki-zakaza/'
      }

      if (response.success === false) {
        input.classList.add('is-invalid')
        if (response.message) {
          this.codeValidationFeedBack = response.message
        }
      }
    }
  },
  components: {
    VueTelInput,
    Timer
  },
}
</script>

<style lang="scss">
@import '/src/assets/scss/components/smsLogin';
</style>

const utils = {
    baseurl: 'https://dtlshop.ru',
    async request(formData = null) {
        try {
            utils.setBaseUrl()
            const headers = {};
            const method = 'POST'
            const link = utils.baseurl + '/assets/components/api/action.php'
            const response = await fetch(link, {
                    method,
                    headers,
                    body: formData
                }
            )
            return await response.json()
        } catch (e) {
            console.warn('Error', e.message)
        }
    },

    async send(data) {
        const formData = new FormData()
        data.forEach(item => {
            formData.append(item.name, item.value)
        });
        return await utils.request(formData)
    },

    setBaseUrl() {
        if (location.hostname === 'localhost') {
            utils.baseurl = 'https://dev.dtlshop.ru'
        }
        if (location.hostname === 'dev.dtlshop.ru') {
            utils.baseurl = 'https://dev.dtlshop.ru'
        }
        if (location.hostname === 'dtlshop.ru') {
            utils.baseurl = 'https://dtlshop.ru'
        }
    },
}

export default utils
<template>
  <div class="map__container" style="height: 577px;" id="map"></div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "Map",
  props: ['user_id', 'pointId'],
  created() {
    if (this.$parent.pointId !== 0) {
      this.id = this.$parent.pointId
    }
    this.getPoints()
  },
  watch: {
    pointId() {
      this.id = this.$parent.pointId
      this.getPoints()
    }
  },
  data() {
    return {
      objectManager: '',
      myMap: '',
      id:0
    }
  },
  methods: {
    async getPoints() {
      const response = await utils.send([
        {name: 'action', value: 'getPointsListForMap'},
        {name: 'user_id', value: this.user_id},
        {name: 'id', value: this.id},
      ])
      if (response.data.features) {
        await this.buildMap(response.data);
      }
    },
    async buildMap(features) {
      const vm = this;
      if (this.myMap !== '') {
        this.myMap.destroy();
      }

      this.objectManager = new window.ymaps.ObjectManager({
        clusterize: true,
        gridSize: 32,
        clusterDisableClickZoom: false,
      });

      this.myMap = new window.ymaps.Map('map',
          {
            center: [45.292394, 34.135305],
            zoom: 8,
            controls: [],
          },
          {searchControlProvider: 'yandex#search'},
          this.objectManager
      );

      // Чтобы задать опции одиночным объектам и кластерам,
      // обратимся к дочерним коллекциям ObjectManager.
      //mapController.objectManager.objects.options.set('preset', 'islands#greenDotIcon');
      this.objectManager.clusters.options.set('clusterIconColor', '#2956A1');

      this.myMap.geoObjects.add(this.objectManager);

      this.objectManager.events.add([
        'click'
      ], function (e) {
        const target = e.get('objectId');
        const feature = features.features.find(object => object.id == target);
        if (feature) {
          vm.$emit('select', {
            id: feature.id,
            name: feature.properties.name
          })
        }
      });

      if (features.features.length > 0) {
        if (features.features.length === 1) {
          this.myMap.setCenter(features.features[0].geometry.coordinates, 13.6);
        }

        this.objectManager.add(features);

        this.myMap.geoObjects.add(this.objectManager);

        if (features.features.length > 1) {
          this.myMap.setBounds(this.objectManager.getBounds());
        }
      }
    },
  }
}
</script>
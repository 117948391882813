<template>
  <form class="order__form">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-12">
            <h4>Данные получателя:</h4>

            <div class="form-group row input-parent required">
              <label class="col-md-4 col-form-label" for="receiver">
                Имя <span class="required-star">*</span>
              </label>
              <div class="col-md-8">
                <input
                    type="text"
                    id="receiver"
                    placeholder="Имя"
                    class="form-control required"
                    v-model="receiver"
                    @change="addToOrder('receiver')"
                >
              </div>
            </div>

            <div class="form-group row input-parent">
              <label class="col-md-4 col-form-label" for="lastname">Фамилия</label>
              <div class="col-md-8">
                <input
                    type="text"
                    id="lastname"
                    placeholder="Фамилия"
                    class="form-control"
                    v-model="lastname"
                    @change="addToOrder('lastname')"
                >
              </div>
            </div>

            <div class="form-group row input-parent required">
              <label class="col-md-4 col-form-label">
                Номер телефона <span class="required-star">*</span>
              </label>
              <div class="col-md-8">
                <vue-tel-input
                    :defaultCountry="'ru'"
                    :mode="'international'"
                    :validCharactersOnly="true"
                    v-model="phone"
                    :dropdownOptions="telDropdownOptions"
                    :inputOptions="telInputOptions"
                    :styleClasses="telInputStyleClasses"
                    @validate="telInputValidate"
                    @input="telInputMask"
                ></vue-tel-input>
              </div>
            </div>

            <div class="form-group row input-parent required">
              <label class="col-md-4 col-form-label" for="email">
                Email <span class="required-star">*</span>
              </label>
              <div class="col-md-8">
                <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    class="form-control required"
                    v-model="email"
                    @change="addToOrder('email')"
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-md-4 col-form-label">
                Тип пользователя
              </label>

              <div class="col-md-8">
                <div class="checkbox">
                  <label class="col-form-label payment input-parent">
                    <input
                        type="radio"
                        class="receiver_type"
                        value="1"
                        id="type_1"
                        v-model="type"
                    >
                    Физическое лицо
                  </label>
                </div>

                <div class="checkbox">
                  <label class="col-form-label payment input-parent">
                    <input
                        type="radio"
                        class="receiver_type"
                        value="2"
                        id="type_2"
                        v-model="type"
                        @change="addToOrder('type')"
                    >
                    Юридическое лицо
                  </label>
                </div>
              </div>
            </div>

            <div
                v-if="type === '2'"
                class="form-group row input-parent inn_row">
              <label class="col-md-4 col-form-label" for="inn">
                ИНН
              </label>
              <div class="col-md-8">
                <input
                    type="text"
                    id="inn"
                    placeholder="ИНН"
                    value=""
                    class="form-control"
                    v-model="inn"
                    @change="addToOrder('inn')"
                >
              </div>
            </div>

            <h4 class="mt-5">Доставка и оплата</h4>

            <div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">
                  Способы оплаты: <span class="required-star">*</span>
                </label>

                <div class="col-md-8">
                  <div
                      v-for="item in payments"
                      :key="item.id"
                      class="checkbox">
                    <label class="col-form-label payment input-parent">
                      <input
                          type="radio"
                          :value="item.id"
                          :id="'payment_' + item.id"
                          v-model="payment"
                          @change="addToOrder('payment')"
                      >
                      {{ item.name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div class="form-group row">
                <label class="col-md-4 col-form-label">
                  Варианты доставки: <span class="required-star">*</span>
                </label>

                <div class="col-md-8">
                  <div
                      v-for="item in deliveries"
                      :key="item.id"
                      class="checkbox">
                    <label class="col-form-label delivery input-parent">
                      <input
                          type="radio"
                          :value="item.id"
                          :id="'delivery_' + item.id"
                          v-model="delivery"
                          @change="changeDelivery"
                      >
                      {{ item.name }} </label>
                  </div>

                </div>
              </div>
            </div>

            <template v-if="delivery === 3 || delivery === 2">
            <div class="form-group row input-parent">
              <label class="col-md-4 col-form-label" for="city">Город</label>
              <div class="col-md-8">
                <input
                    type="text"
                    id="city"
                    placeholder="Город"
                    class="form-control"
                    v-model="city"
                    @change="addToOrder('city')"
                >
              </div>
            </div>
            </template>

            <template v-if="delivery === 3">
              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="street">Улица</label>
                <div class="col-md-8">
                  <input
                      type="text"
                      id="street"
                      placeholder="Улица"
                      class="form-control"
                      v-model="street"
                      @change="addToOrder('street')"
                  >
                </div>
              </div>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="building">Дом</label>
                <div class="col-md-8">
                  <input
                      type="text"
                      id="building"
                      placeholder="Дом"
                      class="form-control"
                      v-model="building"
                      @change="addToOrder('building')"
                  >
                </div>
              </div>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="room">Кв.\ Оф.</label>
                <div class="col-md-8">
                  <input
                      type="text"
                      id="room"
                      placeholder="Кв.\ Оф."
                      class="form-control"
                      v-model="room"
                      @change="addToOrder('room')"
                  >
                </div>
              </div>
            </template>

            <h4 class="mt-5">Комментарий к заказу:</h4>

            <div class="form-group row input-parent">
              <label class="col-md-4 col-form-label" for="comment">
                Комментарий
              </label>
              <div class="col-md-8">
                    <textarea
                        id="comment"
                        placeholder="Комментарий"
                        class="form-control"
                        v-model="comment"
                        @change="addToOrder('comment')"
                    >
                    </textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-3">

        <div class="d-flex flex-column align-items-center justify-content-center justify-content-md-end">
          <MiniCart/>

          <template v-if="false">
            <div class="alert alert-danger mt-5" role="alert" hidden="" id="deliveryRemainsDanger">
              <p>
                Доставка: Дополните заказ на <strong class="remains_cost"></strong> для бесплатной
                доставки
                по СПб.
              </p>

              <p>
                Если стоимость товаров по акции составляет менее 50% от общей стоимости заказа, доставка
                выполняется бесплатно, если больше 50%, то стоимость доставки составит 350 ₽.
              </p>
            </div>

            <div class="alert alert-success mt-5" role="alert" id="deliveryRemainsSuccess">
              <p>
                Бесплатная доставка по СПб
              </p>

              <p>
                Если стоимость товаров по акции составляет менее 50% от общей стоимости заказа, доставка
                выполняется бесплатно, если больше 50%, то стоимость доставки составит 350 ₽.
              </p>
            </div>
          </template>

          <button
              class="button order__button ml-md-2 "
              @click.prevent="submit"
          >
            Сделать заказ!
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import {VueTelInput} from 'vue-tel-input'
import MiniCart from "../components/MiniCart";
import utils from "@/utils";

export default {
  name: "OrderAsGuest",
  created() {
    this.addRadios()
  },
  data() {
    return {
      receiver: '',
      lastname: '',
      phone: '',
      email: '',
      type: 1,
      payment: 1,
      delivery: 1,
      comment: '',
      city: '',
      street: '',
      building: '',
      room: '',
      inn: '',
      payments: [
        {
          id: 1,
          name: 'Наличными или картой - в шоу-руме',
        },
        {
          id: 3,
          name: 'Оплата картой онлайн',
        },
        {
          id: 4,
          name: 'Оплата курьеру наличными (по Санкт-Петербургу)',
        },
      ],
      paymentsTemplate: [
        {
          id: 1,
          name: 'Наличными или картой - в шоу-руме',

        },
        {
          id: 3,
          name: 'Оплата картой онлайн',

        },
        {
          id: 4,
          name: 'Оплата курьеру наличными (по Санкт-Петербургу)',

        },
      ],
      deliveries: [
        {
          id: 1,
          name: 'Самовывоз из магазина',
          payments: [1, 3]
        },
        {
          id: 2,
          name: 'Доставка до пункта выдачи заказов',
          payments: [3],
        },
        {
          id: 3,
          name: 'Доставка курьером',
          payments: [3, 4]
        },
      ],
      telInputStyleClasses: ['form-control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled:true
      },
      telInputOptions: {
        placeholder: 'Введите номер телефона',
        required: true,
        name: 'phone'
      },
    }
  },
  methods: {
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form-control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
        this.addToOrder('phone')
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.phone = '+77'
      }
      if (value === '89') {
        this.phone = '+79'
      }
    },
    async changeDelivery() {
      const delivery = this.deliveries.find(item => item.id === this.delivery)
      this.payments = this.paymentsTemplate.filter(item => delivery.payments.includes(item.id))
      this.payment = this.payments[0].id
      await this.addToOrder('delivery')
      await this.addToOrder('payment')

      switch (this.delivery) {
        case 1:
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_point', value: ''},
          ]);
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_provider', value: ''},
          ]);
          break;
        case 2:
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_point', value: 'Через менеджера'},
          ]);
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_provider', value: ''},
          ]);
          break;
        case 3:
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_point', value: ''},
          ]);
          await utils.send([
            {name:'action', value: 'order_add'},
            {name: 'delivery_provider', value: 'Через менеджера'},
          ]);
          break;
      }
    },
    async addToOrder(key) {
      await utils.send([
        {name: 'action', value: 'order_add'},
        {name: key, value: this[key]},
      ])
    },
    async submit() {
      const $form = document.querySelector('.order__form')
      if ($form) {
        const inputs = $form.querySelectorAll('.form-control')
        if (inputs.length > 0) {
          inputs.forEach(input => {
            input.classList.remove('is-invalid');
          })
        }
      }

      this.telInputStyleClasses = ['form-control']

      const response = await utils.send([
        {name: 'action', value: 'order_submit'},
      ])

      if (response.success === true) {
        if (location.hostname === 'dtlshop.ru') {
          this.setEcommerce(response)
        }
        if (response.redirect) {
          setTimeout(() => {
            location.href = response.redirect
          }, 500)
        }
      }
      if (response.success === false) {
        this.loading = false

        if (response.message) {
          window.miniShop2.Message.error(response.message)
        }

        if (response.data.length > 0 && $form) {
          response.data.forEach(item => {
            switch (item) {
              case 'phone':
                this.telInputStyleClasses.push('is-invalid')
                break;
              default:
                if ($form[item]) {
                  $form[item].classList.add('is-invalid')
                }
            }
          })
        }
      }
    },
    async addRadios() {
      await this.addPayment()
      await this.addDelivery()
      this.changeDelivery()
      await this.addType()
    },

    async addPayment() {
      await utils.send([
        {name: 'action', value: 'order_add'},
        {name: 'payment', value: this.payment},
      ])
    },

    async addDelivery() {
       await utils.send([
         {name: 'action', value: 'order_add'},
         {name: 'delivery', value: this.delivery},
       ])
    },

    async addType() {
      await utils.send([
        {name: 'action', value: 'order_add'},
        {name: 'type', value: this.type},
      ])
    },
    setEcommerce(response) {
      const r46_products = [];
      response.products.forEach(product => {
        r46_products.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          amount: product.properties.count,
          stock: true
        });
      });


      if (typeof window.r46 != "undefined") {
        window.r46('track', 'purchase', {
          products: r46_products,
          order: `${response.order.num}`,
          order_price: response.order.cost,
        });
      }

      const dataLayerProducts = [];
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        });
      });

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': 'dtlshop.ru',
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      });
    },
  },
  components: {
    VueTelInput,
    MiniCart,
  },
}
</script>

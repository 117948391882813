<template>
  <div class="row mt-4">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-lg-6 order-1 order-lg-0">
          <div
              v-if="loading"
              class="spinner-border text-danger login__form-spinner" role="status">
            <span class="sr-only">Loading...</span>
          </div>

          <div v-if="providers.length > 0">
            <h3>Выберите курьерскую службу</h3>

            <form class="form order__form delivery__service ms2_form">
              <div class="delivery__list">
                <label
                    v-for="provider in providers"
                    :key="provider.providerKey"
                    class="delivery__method"
                    :class="{'delivery__method--active': provider.active}"
                    @change="changeProvider(provider.providerKey)"
                >
                  <input
                      type="radio"
                      :value="provider.name"
                  >
                  <div class="delivery__method-icon" v-if="provider.logo"><div v-html="provider.logo" /></div>
                  <div class="delivery__method-info">
                    <p class="delivery__method-name">{{ provider.name }}</p>
                    <p class="delivery__method-details">{{ provider.date }} - {{ provider.price }}</p>
                  </div>
                </label>
              </div>

              <h4 class="mt-4">Адрес доставки:</h4>

              <div class="row mt-2">
                <div class="form-group col-12 col-md-6">
                  <label for="street">Улица</label>
                  <input
                      id="street"
                      type="text"
                      class="form-control"
                      v-model="order.street"
                      @change="addToOrder('street')"
                  >
                </div>

                <div class="form-group col-12 col-md-3">
                  <label for="building">Дом</label>
                  <input id="building" type="text" class="form-control"
                         v-model="order.building"
                         @change="addToOrder('building')">
                </div>

                <div class="form-group col-12 col-md-3">
                  <label for="room">Кв \ оф</label>
                  <input id="room" type="text" class="form-control"
                         v-model="order.room"
                         @change="addToOrder('room')"
                  >
                </div>
              </div>

              <h4>Данные получателя:</h4>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="receiver">
                  Имя <span class="required-star">*</span>
                </label>
                <div class="col-md-8">
                  <input type="text" id="receiver" placeholder="Имя"
                         v-model="order.receiver"
                         @change="addToOrder('receiver')"
                         class="form-control">
                </div>
              </div>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="lastname">
                  Фамилия <span class="required-star">*</span>
                </label>
                <div class="col-md-8">
                  <input type="text" id="lastname" placeholder="Фамилия"
                         v-model="order.lastname"
                         @change="addToOrder('lastname')"
                         class="form-control">
                </div>
              </div>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="phone">
                  Номер телефона <span class="required-star">*</span>
                </label>
                <div class="col-md-8">
                  <vue-tel-input
                      :defaultCountry="'ru'"
                      :mode="'international'"
                      :validCharactersOnly="true"
                      v-model="telInputValue"
                      :dropdownOptions="telDropdownOptions"
                      :inputOptions="telInputOptions"
                      :styleClasses="telInputStyleClasses"
                      @validate="telInputValidate"
                      @input="telInputMask"
                  ></vue-tel-input>
                </div>
              </div>

              <div class="form-group row input-parent">
                <label class="col-md-4 col-form-label" for="email">
                  Email <span class="required-star">*</span>
                </label>
                <div class="col-md-8">
                  <input type="email" id="email" placeholder="Email"
                         v-model="order.email"
                         @change="addToOrder('email')"
                         class="form-control">
                </div>
              </div>

              <div class="form-group row">
                <label class="col-md-4 col-form-label">
                  Тип пользователя <span class="required-star">*</span>
                </label>

                <div class="col-md-8">
                  <div class="checkbox">
                    <label class="col-form-label payment input-parent">
                      <input type="radio" name="type" class="receiver_type" value="1" id="type_1"
                             v-model="order.type"
                             @change="addToOrder('type')">
                      Физическое лицо
                    </label>
                  </div>

                  <div class="checkbox">
                    <label class="col-form-label payment input-parent">
                      <input type="radio" name="type" class="receiver_type" value="2" id="type_2"
                             v-model="order.type"
                             @change="addToOrder('type')">
                      Юридическое лицо
                    </label>
                  </div>
                </div>
              </div>

              <div class="form-group mt-4">
                <label for="comment">Комментарий к заказу</label>
                <textarea id="comment" class="form-control"
                          v-model="order.comment"
                          @change="addToOrder('comment')"
                ></textarea>
              </div>

              <button class="button mt-4 order__button" @click.prevent="submit">Сделать заказ</button>

              <p class="mt-2">
                <span style="color:red">*</span> Стоимость доставки указана в качестве справочной информации. Точную
                стоимость доставки вам сообщит менеджер при комплектации заказа
              </p>
            </form>
          </div>
        </div>

        <div class="col-12 col-lg-6">
          <MiniCart
              :user_id="user_id"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import MiniCart from "../components/MiniCart";
import {VueTelInput} from 'vue-tel-input'

export default {
  name: "VyborSluzhbyDostabki",
  components: {
    MiniCart,
    VueTelInput
  },
  created() {
    if (this.$parent.userId !== undefined) {
      this.user_id = this.$parent.userId
    }
    this.getDeliveryMethods()
  },
  methods: {
    async getDeliveryMethods() {
      const response = await utils.send([
        {name: 'action', value: 'getDeliveryMethods'},
        {name: 'user_id', value: this.user_id},
      ])
      if (response.success === true) {
        this.providers = response.data
        this.order = response.order
        if (response.order.phone !== null) {
          this.telInputValue = response.order.phone
        }
        if (response.order.delivery_provider) {
          this.highlightProvider(response.order.delivery_provider)
        }
      }
    },
    telInputValidate(input) {
      this.disabled = !input.valid
      this.telInputStyleClasses = ['form-control']
      if (input.valid === true) {
        this.phone = input.number
        this.telInputStyleClasses.push('is-valid')
        utils.send([
          {name: 'action', value: 'order_add'},
          {name: 'user_id', value: this.user_id},
          {name: 'phone', value: this.phone},
        ])
      }
    },
    telInputMask(value) {
      if (value === '87') {
        this.telInputValue = '+77'
      }
      if (value === '89') {
        this.telInputValue = '+79'
      }
    },
    async changeProvider(providerKey) {
      this.highlightProvider(providerKey)
      await utils.send([
        {name: 'action', value: 'order_add'},
        {name: 'user_id', value: this.user_id},
        {name: 'provider', value: providerKey},
      ])
    },
    highlightProvider(providerKey) {
      this.providers.forEach(provider => {
        if (providerKey === provider.providerKey) {
          provider.active = true
        }
      })
    },
    async addToOrder(key) {
      await utils.send([
        {name: 'action', value: 'order_add'},
        {name: 'user_id', value: this.user_id},
        {name: key, value: this.order[key]},
      ])
    },
    async submit() {
      const $form = document.querySelector('.order__form')
      if($form) {
        const inputs = $form.querySelectorAll('.form-control')
        if (inputs.length > 0) {
          inputs.forEach(input => {
            input.classList.remove('is-invalid')
          })
        }
      }

      this.telInputStyleClasses = ['form-control']

      this.loading = true
      const response = await utils.send([
        {name: 'action', value: 'order_submit'},
        {name: 'user_id', value: this.user_id},
      ])

      if (response.success === true) {
        this.loading = false
        if (location.hostname === 'dtlshop.ru') {
          this.setEcommerce(response)
        }

        if (response.redirect) {
          setTimeout(() => {
            location.href = response.redirect
          }, 500)
        }
      }

      if (response.success === false) {
        this.loading = false
        if (response.message) {
          window.miniShop2.Message.error(response.message)
        }

        if (response.data.length > 0 && $form) {
          response.data.forEach(item => {
            switch (item) {
              case 'delivery_provider':
                window.miniShop2.Message.error('Выберите службу доставки')
                    break;
              case 'phone':
                this.telInputStyleClasses.push('is-invalid')
                break;
              default:
                if ($form[item]) {
                  $form[item].classList.add('is-invalid')
                }
            }
          })
        }
      }
    },
    setEcommerce(response) {
      const r46_products = [];
      response.products.forEach(product => {
        r46_products.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          amount: product.properties.count,
          stock: true
        });
      });


      if (typeof window.r46 != "undefined") {
        window.r46('track', 'purchase', {
          products: r46_products,
          order: `${response.order.num}`,
          order_price: response.order.cost,
        });
      }

      const dataLayerProducts = [];
      response.products.forEach(product => {
        dataLayerProducts.push({
          id: `${product.properties.article}`,
          price: product.properties.price,
          quantity: product.properties.count,
          name: `${product.properties.name}`,
          brand: `${product.properties.brand}`,
          category: `${product.properties.category}`
        });
      });

      window.dataLayer.push({
        'ecommerce': {
          'currencyCode': 'RUB',
          'purchase': {
            'actionField': {
              'id': `${response.order.num}`,
              'affiliation': 'dtlshop.ru',
              'revenue': response.order.cost,
              'tax': '0',
              'shipping': '0',
            },
            'products': dataLayerProducts
          }
        },
        'event': 'ee_event',
        'ee_event_category': 'Enhanced Ecommerce',
        'ee_event_action': 'Purchase',
        'ee_event_non-interaction': 'False',
      });
    },
  },
  data() {
    return {
      loading: false,
      user_id: 0,
      providers: [],
      order: {},
      phone: '',
      telInputValue: '',
      telInputStyleClasses: ['form-control'],
      telDropdownOptions: {
        showDialCodeInSelection: false,
        showFlags: true,
        showDialCodeInList: true,
        disabled:true
      },
      telInputOptions: {
        placeholder: 'Введите номер телефона',
        required: true,
        name: 'phone'
      },
    }
  },
}
</script>
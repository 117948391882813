import Vue from 'vue'
import ModalLocationSelect from "./components/ModalLocationSelect/ModalLocationSelect"
import SmsLogin from "./components/Login/SmsLogin"
import SposobDostavkiZakaza from "./pages/SposobDostavkiZakaza";
import VyborSluzhbyDostabki from "./pages/VyborSluzhbyDostabki";
import DeliveryPoint from "./pages/DeliveryPoint";
import PickupStore from "./pages/PickupStore";
import OrderAsGuest from "./pages/OrderAsGuest";
import ModalAuth from "./components/ModalAuth/ModalAuth";
import LoginForm from  "./components/Login/loginForm"

import 'vue-tel-input/dist/vue-tel-input.css';

Vue.config.productionTip = false

const $modalLocationSelect = document.querySelector('#modal-location-select')
if ($modalLocationSelect) {
    new Vue({
        el: '#modal-location-select',
        data() {
            return {
                defaultCity: $modalLocationSelect.dataset.city
            }
        },
        template: '<ModalLocationSelect/>',
        components: {
            ModalLocationSelect
        }
    })
}

const $modalAuth = document.querySelector('#modal-auth')
if ($modalAuth) {
    new Vue({
        el: '#modal-auth',
        data() {
            return {

            }
        },
        template: '<ModalAuth/>',
        components: {
            ModalAuth
        }
    })
}

const $LoginForm = document.querySelector('#login-form')
if ($LoginForm) {
    new Vue({
        el: '#login-form',
        data() {
            return {

            }
        },
        template: '<LoginForm/>',
        components: {
            LoginForm
        }
    })
}

const $orderLoginForm = document.querySelector('#order-login-form')
if ($orderLoginForm) {
    new Vue({
        el: '#order-login-form',
        data() {
            return {}
        },
        template: '<SmsLogin/>',
        components: {
            SmsLogin
        }
    })
}

// Страница выбор способа доставки
const $sposobDostavki = document.querySelector('#sposob-dostavki')
if ($sposobDostavki) {
    const userId = $sposobDostavki.dataset['userId']
    new Vue({
        el: '#sposob-dostavki',
        data() {
            return {
                userId
            }
        },
        template: '<SposobDostavkiZakaza/>',
        components: {
            SposobDostavkiZakaza
        }
    })
}

// Страница выбор службы доставки в вашем городе
const $sluzhbaDostavki = document.querySelector('#sluzhba-dostavki')
if ($sluzhbaDostavki) {
    const userId = $sluzhbaDostavki.dataset['userId']
    new Vue({
        el: '#sluzhba-dostavki',
        data() {
            return {
                userId
            }
        },
        template: '<VyborSluzhbyDostabki/>',
        components: {
            VyborSluzhbyDostabki
        }
    })
}

// Страница выбор пункта самовывоза в вашем городе
const $deliveryPoint = document.querySelector('#delivery-point')
if ($deliveryPoint) {
    const userId = $deliveryPoint.dataset['userId']
    new Vue({
        el: '#delivery-point',
        data() {
            return {
                userId
            }
        },
        template: '<DeliveryPoint/>',
        components: {
            DeliveryPoint
        }
    })
}

// Страница Самовывоз из магазина в Питере
const $pickupStore = document.querySelector('#pickup-store')
if ($pickupStore) {
    const userId = $pickupStore.dataset['userId']
    new Vue({
        el: '#pickup-store',
        data() {
            return {
                userId
            }
        },
        template: '<PickupStore/>',
        components: {
            PickupStore
        }
    })
}

// Страница заказ как гость-аноним
const $orderGuest = document.querySelector('#order-guest')
if ($orderGuest) {
    new Vue({
        el: '#order-guest',
        data() {
            return {}
        },
        template: '<OrderAsGuest/>',
        components: {
            OrderAsGuest
        }
    })
}